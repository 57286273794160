<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="customerAddressId != null">Edit Business Partner Address</b>
      <b v-else>Create Business Partner Address</b>
    </span>
    <vs-tabs>
      <vs-tab label="Address">
        <div class="tab-text">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <label class="vs-input--label">Status</label>
              <vs-checkbox v-model="data.status" vs-value="Billing Address"
                >Billing Address</vs-checkbox
              >
              <vs-checkbox v-model="data.status" vs-value="Shipping Address"
                >Shipping Address</vs-checkbox
              >
            </div>
          </div>
          <address-component
            :data="data.address"
            @data="setDataAddress"
          ></address-component>
        </div>
      </vs-tab>
      <vs-tab label="Map">
        <div class="tab-text">
          <map-component :data="data.map" @data="setDataMap"></map-component>
        </div>
      </vs-tab>
      <vs-tab label="Contact">
        <div class="tab-text">
          <contact-component
            :data="data.contact"
            @data="setDataContact"
          ></contact-component>
        </div>
      </vs-tab>
      <vs-tab label="Work Operation">
        <div class="tab-text">
          <operation-component
            :data="data.workOperation"
            @data="setDataWorkOperation"
          ></operation-component>
        </div>
      </vs-tab>
      <vs-tab label="Receive">
        <div class="tab-text">
          <receive-operation-component
            :data="data.receiveOperation"
            :dataWorkOperation="data.workOperation"
            @data="setDataReceiveOperation"
          ></receive-operation-component>
        </div>
      </vs-tab>
    </vs-tabs>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import OperationComponent from "./form-component/Operation";
import ContactComponent from "./form-component/Contact";
import MapComponent from "./form-component/Map";
import AddressComponent from "@/components/master/Address";
import ReceiveOperationComponent from "./form-component/ReceiveOperation";

export default {
  components: {
    flatPickr,
    OperationComponent,
    ContactComponent,
    MapComponent,
    AddressComponent,
    ReceiveOperationComponent,
  },
  props: {
    customerId: {
      type: Number,
    },
    customerAddressId: {
      type: Number,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          customerId: null,
          status: [],
          address: {
            address: "",
            postalCode: "",
            countryName: "",
            provinceName: "",
            cityName: "",
            districtName: "",
            subdistrictName: "",
          },
          map: {
            address: "",
            latitude: "",
            longitude: "",
          },
          contact: {
            name: "",
            mobile: "",
            phone: "",
            email: "",
            fax: "",
          },
          receiveOperation: [],
          workOperation: [],
        },
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          if (this.customerAddressId) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      let workOperation = [];
      let receiveOperation = [];
      this.data.workOperation.forEach((item) => {
        if (item.checked) {
          workOperation.push({
            day: item.day,
            start_time: item.start_time,
            end_time: item.end_time,
          });
        }
      });
      this.data.receiveOperation.forEach((item) => {
        if (item.checked) {
          receiveOperation.push({
            day: item.day,
            start_time: item.start_time,
            end_time: item.end_time,
          });
        }
      });
      return {
        customer_id: this.customerId,
        country: this.data.address.countryName,
        province: this.data.address.provinceName,
        city: this.data.address.cityName,
        district: this.data.address.districtName,
        sub_district: this.data.address.subdistrictName,
        address: this.data.address.address,
        postal_code: this.data.address.postalCode,
        status: JSON.stringify(this.data.status),

        map_address: this.data.map.address,
        latitude: this.data.map.latitude,
        longitude: this.data.map.longitude,

        contact_name: this.data.contact.name,
        contact_mobile: this.data.contact.mobile,
        phone: this.data.contact.phone,
        email: this.data.contact.email,
        fax: this.data.contact.fax,

        work_operations: workOperation,
        receive_operations: receiveOperation,
      };
    },
    postData() {
      this.$http
        .post("/api/v1/master/customer-address", this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New Customer Address Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(
          "/api/v1/master/customer-address/" + this.customerAddressId,
          this.paramData()
        )
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Customer Adress Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      if (this.customerAddressId) {
        this.$http
          .get("/api/v1/master/customer-address/" + this.customerAddressId)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.data.customerId = resp.data.customer_id;
              this.data.address.address = resp.data.address;
              this.data.address.postalCode = resp.data.postal_code;
              this.data.address.countryName = resp.data.country;
              this.data.address.provinceName = resp.data.province;
              this.data.address.cityName = resp.data.city;
              this.data.address.districtName = resp.data.district;
              this.data.address.subdistrictName = resp.data.sub_district;

              if (resp.data.status) {
                this.data.status = JSON.parse(resp.data.status);
              }

              this.data.map.address = resp.data.map_address;
              this.data.map.latitude = resp.data.latitude;
              this.data.map.longitude = resp.data.longitude;

              this.data.contact.name = resp.data.contact_name;
              this.data.contact.mobile = resp.data.contact_mobile;
              this.data.contact.phone = resp.data.phone;
              this.data.contact.email = resp.data.email;
              this.data.contact.fax = resp.data.fax;

              if (resp.data.work_operation) {
                if (resp.data.work_operation.work_operation_detail) {
                  this.data.workOperation = [];
                  resp.data.work_operation.work_operation_detail.forEach(
                    (item) => {
                      this.data.workOperation.push({
                        day: item.work_day.day,
                        checked: true,
                        start_time: item.work_time.start_time
                          .toString()
                          .replace(/\./g, ":"),
                        end_time: item.work_time.end_time
                          .toString()
                          .replace(/\./g, ":"),
                      });
                    }
                  );
                }
              }
              if (resp.data.receive_time) {
                if (resp.data.receive_time.work_operation_detail) {
                  this.data.receiveOperation = [];
                  resp.data.receive_time.work_operation_detail.forEach(
                    (item) => {
                      this.data.receiveOperation.push({
                        day: item.work_day.day,
                        checked: true,
                        start_time: item.work_time.start_time
                          .toString()
                          .replace(/\./g, ":"),
                        end_time: item.work_time.end_time
                          .toString()
                          .replace(/\./g, ":"),
                      });
                    }
                  );
                }
              }
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      } else {
        if (this.customerId) {
          this.$http
            .get("/api/v1/master/customer/" + this.customerId)
            .then((resp) => {
              this.$vs.loading.close();
              if (resp.code == 200) {
                this.data.address.address = resp.data.address;
                this.data.address.postalCode = resp.data.postal_code;
                this.data.address.countryName = resp.data.country;
                this.data.address.provinceName = resp.data.province;
                this.data.address.cityName = resp.data.city;
                this.data.address.districtName = resp.data.district;
                this.data.address.subdistrictName = resp.data.sub_district;
                // this.data.map.address = resp.data.map_address;
                // this.data.map.latitude = resp.data.latitude;
                // this.data.map.longitude = resp.data.longitude
              } else {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        }
      }
    },
    setDataReceiveOperation(val) {
      this.data.receiveOperation = val;
    },
    setDataWorkOperation(val) {
      this.data.workOperation = val;
    },
    setDataContact(val) {
      this.data.contact = val;
    },
    setDataMap(val) {
      this.data.map = val;
    },
    setDataAddress(val) {
      this.data.address = val;
    },
  },
  mounted() {
    this.getData();
  },
  computed: {},
  watch: {
    customerAddressId(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
  },
};
</script>
<style >
</style>
