<template>
  <div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input class="w-full" label="Address" v-model="mutableData.address" name="Address" />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-1/2">
        <vs-input class="w-full" label="Latitude" v-model="mutableData.latitude" name="Latitude" />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full"
          label="Longitude"
          v-model="mutableData.longitude"
          name="Longitude"
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <maps :data="dataMap" @data="setData"></maps>
      </div>
    </div>
  </div>
</template>
<script>
import Maps from "@/views/oms/master/customer-address/form-component/Maps";
export default {
  components: {
    Maps
  },
  props: {
    data: Object
  },
  data() {
    return {
      mutableData: {
        address: "",
        latitude: "",
        longitude: ""
      },
      dataMap: null
    };
  },
  methods: {
    setData(val) {
      this.mutableData.latitude = val.latitude;
      this.mutableData.longitude = val.longitude;
      this.mutableData.address = val.address;
    }
  },
  mounted() {
    this.mutableData.address = this.data.address;
    this.mutableData.latitude = this.data.latitude;
    this.mutableData.longitude = this.data.longitude;
  },
  watch: {
    mutableData: {
      deep: true,
      handler(val) {
        this.dataMap = val;
        this.$emit("data", val);
      }
    }
  }
};
</script>